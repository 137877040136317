import { BaseElement, html, css } from 'Elements';

import './LanguagesSelector.js';
import './ThemesSelector.js';
import './LayoutSelector.js';

class SettingsIcon extends BaseElement { 
  static get styles() {
    return css`
      :host {
        display: block;
        text-align:center;
        position:relative;
      }

      m-icon {
        font-size: 32px;
      }

      sl-card::part(body) {
        width:170px;
        display:flex;
        flex-direction:column;
        align-items:flex-start;
        padding:0px;
        padding-bottom:10px;
        padding-left:10px;
        padding-right:10px;
        text-align:left;
        font-size:0.9em;
      }

      .big {
        font-size:50px;
        border-radius:50%;
        margin:0px;
        padding:0px;
      }

      .identity {
        font-size:12px;
        margin:0px;
        padding:0px;
      }

      .white {
        color:white;
      }

      .menu > * {
        width:100%;
        font-size:  0.86rem;
      }

      p {
        border-bottom:1px solid var(--sl-color-gray-200);
        margin:0px;
        line-height:30px;
      }

      sl-menu-item::part(base) {
        font-size:0.5em;
      }

      @media (max-width: 667px) {
        m-icon {
          font-size: 32px;
        }
      }
    `;
  }

  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
            language:'Language',
            theme:'Theme',
            layout:'Display',
          }
        },
        french:{
          translation: {
            language:'Langue',
            theme:'Theme',
            layout:'Affichage',
          }
        }
      }
    ]
  }

  hideDropdown() {
    this.dropdown = this.dropdown || this.shadowRoot.querySelector('sl-dropdown');
    this.dropdown.hide();
  }

  render() {
    return html`
      <sl-dropdown>
        <m-icon slot="trigger" clickable name="more_vert" class="white"></m-icon>
        <sl-card class="menu">
          <br/>
          <p>${this._tl('language')}</p>
          <languages-selector reload></languages-selector>
          <p>${this._tl('theme')}</p>
          <theme-selector></theme-selector>
          <p>${this._tl('layout')}</p>
          <layout-selector></layout-selector>
        </sl-card>
      </sl-dropdown>
    `;
  }
}

customElements.define('settings-icon', SettingsIcon);