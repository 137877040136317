import { BaseElement, html, css } from 'Elements';
import { Session } from 'Utils';
import { Router } from 'Router';
import App from 'App';

class SessionIcon extends BaseElement {
  static get properties() {
    return {
      logged: { type: Boolean },
    };
  }
  
  static get styles() {
    return css`
      :host {
        display: block;
        font-family: Calibri, sans-serif;
        text-align:center;
        position:relative;
        --size:30px;
      }

      sl-button.round::part(base) {
        border-radius:50%;
        height:var(--size);
        width:var(--size);
      }

      sl-button.round::part(label) {
        padding:0px;
        display:flex;
        justify-content:center;
        align-items:center;
      }

      m-icon {
        font-size: 30px;
      }

      sl-card::part(body) {
        display:flex;
        flex-direction:column;
        align-items:center;
        gap:10px;
        margin:20px !important;
        margin-top:0px;
        margin-left:30px !important;
        margin-right:30px !important;
        padding:0px;
      }

      .big {
        font-size:50px;
        border-radius:50%;
        margin:0px;
        padding:0px;
      }

      .identity {
        font-size:14px;
        margin:0px;
        padding:0px;
        line-height:15px;
      }

      .white {
        color:white;
      }

      .red {
        border-bottom:2px solid var(--sl-color-red-700);
      }

      .orange {
        border-bottom:2px solid var(--sl-color-warning-500);
      }

      .invite_2fa::before,
      .auth_low::before {
        position:absolute;
        content: "\\f014";
        display: flex;
        align-items: center;
        justify-content: center;
        left:-6px;
        z-index:3;
        top:-0px;
        position: absolute;
        font-family: "Material Symbols Sharp";
        font-weight: normal;
        font-style: bold;
        font-size: 18px;
        letter-spacing: normal;
        text-transform: none;
        background-color:var(--sl-color-danger-500);
        color:white;
        height:13px;
        width:12px;
        border-radius:50%;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
        font-feature-settings: "liga";
        user-select:none;
      }

      .auth_2fa::before {
        position:absolute;
        content: "\\e8e8";
        display: flex;
        align-items: center;
        justify-content: center;
        left:-2px;
        z-index:3;
        top:-0px;
        position: absolute;
        font-family: "Material Symbols Sharp";
        font-weight: normal;
        font-style: bold;
        font-size: 18px;
        letter-spacing: normal;
        text-transform: none;
        background-color:var(--sl-color-success-800);
        color:white;
        height:13px;
        width:12px;
        border-radius:50%;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
        font-feature-settings: "liga";
        user-select:none;
      }

      p {
        line-height:20px;
      }

      .buttons {
        display:flex;
        gap:10px;
        justify-content:space-between;
      }

      box-styled {
        font-size:0.8em;
        margin-top:10px;
        color:var(--sl-color-primary-800);
        white-space:nowrap;
      }

      a-route {
        color:var(--sl-color-primary-800);
      }

      sl-avatar {
        --size:34px;
        cursor:pointer;
        padding-bottom:4px;
      }

      sl-avatar::part(base) {
        background-color:transparent;
        border:2px solid white;
        color:white;
        padding-top:1px;
        text-shadow: 0px 0px 3px #000000;
      }

      .invite {
        margin-top:10px;
        position:relative;
        padding-left:15px;
        background-color:var(--sl-color-warning-100);
      }

      .invite_2fa {
        font-size: 0.8em;
        line-height: 1.4rem;
        padding-left: 6px;
        padding-right: 6px;
        white-space: nowrap;
      }

      .invite_2fa::before {
        left:5px;
        top:4px;
      }

      @media (max-width: 667px) {
        sl-avatar {
          --size:30px;
        }

        .invite_2fa::before,
        .auth_low::before,
        .auth_2fa::before {
          left:-6px;
        }
      }

    `;
  }

  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
            my_account:'My Account',
            button_logout:'Logout',
            logout_confirm_label:'Logout',
            logout_confirm_question:'Are you sure you want to logout ?',
            logout_confirm_yes:'Yes, logout',
            cancel:'Cancel'
          }
        },
        french:{
          translation: {
            my_account:'Mon compte',
            button_logout:'Se déconnecter',
            logout_confirm_label:'Déconnexion',
            logout_confirm_question:'Êtes-vous sûr de vouloir vous déconnecter ?',
            logout_confirm_yes:'Oui, me déconnecter',
            cancel:'Annuler'
          }
        }
      }
    ]
  }

  constructor() {
    super();
    this.logged = false;
    this.resizeTimeout = null;
    this.handleResize = this.handleResize.bind(this);
    this.handleSessionRefreshed = this.handleSessionRefreshed.bind(this);
    this.handleSessionExpired = this.handleSessionExpired.bind(this);
  }

  async connectedCallback() {
    super.connectedCallback();
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('session-refreshed', this.handleSessionRefreshed);
    window.addEventListener('session-expired', this.handleSessionExpired);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('session-refreshed', this.handleSessionRefreshed);
    window.removeEventListener('session-expired', this.handleSessionExpired);
  }

  handleSessionExpired() {
    this.shadowRoot.querySelector('#session_expired').show();
  }

  async handleSessionRefreshed() {
    this.session = await Session.get();
    this.requestUpdate();
  }

  async firstUpdated() {
    this.session = await Session.get();
    if (!this.session) return;
    if (Session.isAuthenticated()) {
      //this.emailFirstLetter = session.email.charAt(0).toUpperCase();
      this.logged = true;
    }
    this.requestUpdate();
  }

  handleResize() {
    clearTimeout(this.resizeTimeout);
    this.resizeTimeout = setTimeout(() => this.requestUpdate(), 200);
  }

  handleLoginClick() {
    window.location.href = `/auth/login?kc_locale=${App.config.lg}&dark=${App.config.isDark}&url=${window.location.pathname}`;
  }

  handleAccountClick() {
    this.hideDropdown();
    Router.go('/private/user/account');
  }

  hideDropdown() {
    this.dropdown = this.dropdown || this.shadowRoot.querySelector('sl-dropdown');
    this.dropdown.hide();
  }

  handleLogout() {
    this.hideDropdown();
    this.dialog = this.dialog || this.shadowRoot.querySelector('#modal-logout') || document.querySelector('#modal-logout');
    this.dialog.show();
  }

  handleConfirmLogout() {
    let url = Session.getLogoutUrl();
    
    const currentPort = window.location.port;
    if (currentPort.length) {
      url += ':' + currentPort;
    }

    url+='/auth/logout';

    if (!this.session.ith) {
      console.error('handleConfirmLogout: id_token_hint is missing !');
      return;
    }
    
    url+= '&id_token_hint='+this.session.ith;
    window.location.href = url;
  }

  getInitials() {
    return this.session?.firstname?.charAt(0)?.toUpperCase() + this.session?.lastname?.charAt(0)?.toUpperCase();
  }


  render() {

    if (typeof this.session === 'undefined') {
      return;
    }

    let iconCss = '';
    if (Session.isBoss()) {
      iconCss = 'red';
    } else if (Session.isPrivileged()) {
      iconCss = 'orange';
    }


    const btLoginContent = window.innerWidth > 660 
      ? html`<sl-button size="small" title="Se connecter" pill @click="${this.handleLoginClick}">Connexion</sl-button>` 
      : html`<sl-button size="small" title="Se connecter" variant="text" pill @click="${this.handleLoginClick}" class="round"><m-icon nogradient class="white" name="account_circle"></m-icon>
        </sl-button>
        `;

    const authLevel = Session.authMethod === 'form' ? 'auth_low' : 'auth_2fa';
    // for dev
    //const authLevel = Session.authMethod === 'form' ? 'auth_2fa' : 'auth_low';

    return html`
      ${this.logged
        ? html`
          <sl-dropdown>
            <sl-avatar slot="trigger" --size="10px" initials="${this.getInitials()}" class="${iconCss} ${authLevel}"></sl-avatar>
            <sl-card>
              <m-icon name="account_circle" class="big"></m-icon>
              <div class="identity">${this.session.firstname} ${this.session.lastname}</div>
              <div class="identity">${this.session.email}</div>

              ${Session.authMethod === 'form'
                ? html`<div class="invite"><div class="invite_2fa"><a-route href="/private/user/account#security_auth">Activez la double authentification</a-route></div></div>`
                : ''
              }

              <sl-button size="medium" variant="text" @click="${this.handleAccountClick}">${this._tl('my_account')}</sl-button>
              <sl-button size="medium" variant="primary" @click="${this.handleLogout}">${this._tl('button_logout')}</sl-button>
            </sl-card>
          </sl-dropdown>
          
          <modal-dialog label="${this._tl('logout_confirm_label')}" id="modal-logout">
            <div>
              <p>${this._tl('logout_confirm_question')}</p>
            </div>
            <sl-button slot="bt1" variant="text" close="true">${this._tl('cancel')}</sl-button>
            <sl-button slot="bt2" variant="primary" @click="${this.handleConfirmLogout}">${this._tl('logout_confirm_yes')}</sl-button>
          </modal-dialog>

          <modal-dialog id="session_expired" label="session expirée">
            <p>Votre session a expiré, veuillez vous reconnecter.</p>
            <sl-button slot="bt1" variant="text"></sl-button>
            <sl-button slot="bt2" variant="primary" @click=${this.handleLoginClick}>Continuer</sl-button>
          </modal-dialog>
        `
        : html`${btLoginContent}`
      }
    `;
  }
}

customElements.define('session-icon', SessionIcon);